.archon-fight-header {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: $large-gap;
  row-gap: $tiny-gap;
  align-items: center;
  background: $level-1-bg;
  border-bottom: 1px solid $level-1-border;

  &__encounter {
    display: flex;
    gap: $small-gap;
    align-items: center;
    padding: $tiny-gap $smaller-gap;

    &:hover {
      cursor: pointer;
      background: $dark-blue;
    }
  }

  &__encounter-name {
    font-size: pxToRem(17);
    font-weight: bold;
    letter-spacing: -0.01em;
  }

  &__encounter-metadata {
    font-size: 15px;
    display: flex;
    gap: $smaller-gap;
    align-items: flex-end;
  }

  &__encounter-start-time {
    font-size: pxToRem(14);
    line-height: pxToRem(21);
    color: $white-700;
  }
}
